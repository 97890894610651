import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Container, Nav, Image, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { doLogin, doRegisterUser } from './features/modal/modalSlice';

import { setCurrentUser } from './features/user/userSlice';

import { HasPrivilege } from './features/user/util';


import LogoImage from './Logo_Head.svg';
import './index.css';
export default function Header() {
  const { currentUser } = useSelector(store => store.user);
  
  const dispatch = useDispatch();
  const displayUser = HasPrivilege('viewuser');
  
  
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 84; // Change this to the amount of scrolling needed to make the navbar sticky
      setIsScrolled(scrolled);
    };
    if (currentUser.isLoggedIn === false) {
      let user = retrieveCurrentUser()
      if (user) {
        dispatch(setCurrentUser(user));
      }
      else{
        dispatch(doLogin());
      }
    }
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  
  
  const storeCurrentUser = (user) => {
    try {
      localStorage.setItem('netmon_currentUser', JSON.stringify(user));
      console.log('currentUser stored in local storage');
    } catch (error) {
      // Handle possible errors here
      console.error('Error storing currentUser in local storage:', error);
    }
  };
  const retrieveCurrentUser = () => {
    try {
      const user = localStorage.getItem('netmon_currentUser');
      return user ? JSON.parse(user) : null;
    } catch (error) {
      // Handle possible errors here
      console.error('Error retrieving currentUser from local storage:', error);
      return null;
    }
  };

  const displayHeader = (currentUser, displayUser) => {
    return (
      <Navbar
        expand="lg"
        className={`header navbar-dark ${isScrolled ? 'sticky-navbar' : ''}`}
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <Image r src={LogoImage} alt="logo" width="40" height="34" />{' '}
              Nework Monitor
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {currentUser.isLoggedIn ? (
              <Nav className="me-auto">
                <LinkContainer to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
               
                

                {displayUser ? (
                  <LinkContainer to="/users">
                    <Nav.Link>Users</Nav.Link>
                  </LinkContainer>
                ) : (
                  ''
                )}
              </Nav>
            ) : (
              <Nav className="me-auto"></Nav>
            )}
            <Nav pullRight>
              {currentUser.isLoggedIn === false ? (
                <Nav className="me-auto">
                  <Nav.Link
                    type="button"
                    className=""
                    onClick={() => {
                      dispatch(doLogin());
                    }}
                  >
                    Login
                  </Nav.Link>

                  <Nav.Link
                    onClick={() => {
                      dispatch(doRegisterUser());
                    }}
                  >
                    Register
                  </Nav.Link>
                </Nav>
              ) : (
                <LinkContainer to="/logout">
                  <Nav.Link>Logout</Nav.Link>
                </LinkContainer>
              )}
           
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };

  return <header>{displayHeader(currentUser, displayUser)}</header>;
}

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Row, Col } from 'react-bootstrap';
import {
  netmonState_powerMonDevices,
  getPowerMonDevices,
  getPowerMons
} from '../netmon/netmon.state';
import PowerMonChart from './powerMonChart';

const CurrentPowerOverview = () => {
  useEffect(() => {
    getPowerMonDevices();
    

    console.log(netmonState_powerMonDevices.value);
  }, []);

  return (
    <div>
      {netmonState_powerMonDevices.value.map((device, index) => (
  <PowerMonChart key={index} deviceData={device} />
))}
    </div>
  );
};

export default CurrentPowerOverview;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { getDevices, netmonState_devices,netmonState_device } from './netmon.state';

const NetmonDevices = () => {
  let navigate = useNavigate();

  useEffect(() => {
    getDevices();
    console.log(netmonState_devices.value);
  }, []);

  const handleRowClick = (device) => {
    console.log(device);
    netmonState_device.value = device;
    navigate('/p2pConfigs');
  };

  return (
    <div>
      <h1>Netmon Devices</h1>
      <Table responsive className="table table-hover table-striped text-white">
        <thead>
          <tr>
            <th>Device Name</th>
            <th>Device IP</th>
            {/* Add other device properties as table headers here */}
          </tr>
        </thead>
        <tbody>
          {netmonState_devices.value.map((device) => (
            <tr key={device.device_name} onClick={() => handleRowClick(device)}>
              <td>{device.device_name}</td>
              <td>{device.host_ip}</td>
              {/* Add other device properties as table data here */}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default NetmonDevices;
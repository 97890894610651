import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { Container, Row, Col } from 'react-bootstrap';
import { getPowerMons } from '../netmon/netmon.state';
import { use } from 'echarts';
import { set } from 'react-ga';

const PowerMonChart = ({ deviceData }) => {
  const [categoryArray, setCategory] = useState([]);
  const [data1, setData1] = useState([]);
  const [selectedData, setSelectedData] = useState('');
  const [startdays, setStartDays] = useState('7');
  const [power, setPower] = useState([]);

  const fetchData = async () => {
    
    console.log('fetching data');
    //getPowerMons();
    let tcat = [];
    let data1 = [];
    //setPower(await getPowerMons());
    console.log(power);
    for (let m of power) {
      data1.push(Number(m[selectedData]).toFixed(2)); // use selectedData to access the property
      let dt = new Date(Math.floor(m.timestamp));
      tcat.push(
        dt.toLocaleString('en-us', {
          minute: 'numeric',
          hour12: true,
          hour: 'numeric',
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
      );
    }
    setData1(data1);
    setCategory(tcat);
  };

  useEffect(async() => {
    console.log('data Changed  ' + startdays);
    setPower(await getPowerMons(deviceData.device_name,startdays));
    fetchData();
    
  }, [selectedData,startdays]);

  
  useEffect(() => {
    const fetchData = async () => {
      
      setPower(await getPowerMons(deviceData.device_name, startdays));
      setSelectedData('pv_voltage');
      //setStartDays(7);
    };
    fetchData();
  }, []);
  //onclick event for setting start days
  const handleStartDays = async (e) => {
    const startDaysValue = parseInt(e.target.value);
    setStartDays(startDaysValue);
    setPower(await getPowerMons(deviceData.device_name, startDaysValue));
    fetchData();
  };


  const eChartsOption = {
    textStyle: {
      color: '#fff'
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },
    yAxis: {
      type: 'value'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100
      },
      {
        type: 'inside',
        start: 0,
        end: 100
      }
    ],
    series: [
      {
        name: selectedData,
        type: 'bar',
        data: data1,
        itemStyle: {
          color: 'green' // replace with your color
        }
      }
    ]
  };

  return (
    <Container style={{ marginTop: '70px' }}>
    <Row>
      <Col><h5>{deviceData.name}</h5></Col>
      <Col><h5>Solar Current: {deviceData.powerMonCurrent.pv_current}</h5></Col>
      <Col><h5>Battery: {deviceData.powerMonCurrent.battery_percentage}%</h5></Col>
      <Col><h5>Today Amp/hour: {deviceData.powerMonCurrent.charging_amp_hours_today}</h5></Col>
      <Col>
      <select
        value={selectedData}
        onChange={e => setSelectedData(e.target.value)}
      >
        <option value="pv_voltage">PV Voltage</option>
        <option value="battery_percentage">Battery</option>
        <option value="battery_voltage">Battery Voltage</option>
        <option value="battery_current">Battery Current</option>
        <option value="pv_current">PV Current</option>
        <option value="charging_amp_hours_today">Daily Amp/hour</option>
      </select>
      <select
        value={startdays}
        onChange={e => setStartDays(e.target.value)}
      >
        <option value='7'>7 Days</option>
        <option value='14'>14 Days</option>
        <option value='30'>30 Days</option>
        <option value='90'>90 Days</option>
        
      </select>
      </Col></Row><ReactECharts option={eChartsOption} />
      </Container>
  );
};

export default PowerMonChart;

import React, { useEffect, useState } from 'react';
import { getP2PConfigs,netmonState_p2pConfigs,netmonState_device } from './netmon.state'; // adjust the path as necessary

const P2PConfigs = () => {
    
    useEffect(() => {
        async function fetchP2PConfigs() {
            const deviceName = 'test'; // replace with your device name
            await getP2PConfigs(netmonState_device.value.device_name);
            
        }

        fetchP2PConfigs();
    }, []);

    return (
        <div>
            <h1>P2P Configs</h1>
            <table>
                <thead>
                    <tr>
                        <th>Config Name</th>
                        {/* Add other config properties as table headers here */}
                    </tr>
                </thead>
                <tbody>
                    {netmonState_p2pConfigs.value.map((config) => (
                        <tr key={config.device_name}>
                            <td>{config.device_name}</td>
                            <td>{config.test_ip}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default P2PConfigs;
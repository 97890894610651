import { signal } from "@preact/signals-react";
import axios from "axios";
export const netmonState_devices = signal([]);
export const netmonState_device = signal({});
export const netmonState_powerMonitors = signal([]);
export const netmonState_powerMonDevices = signal([]);
export const netmonState_selectedPowerMonDevice = signal({});
const netmonState_p2pSpeeds = signal([]);
export const netmonState_p2pConfigs = signal([]);


const baseUrl = process.env.REACT_APP_NETMONSERVER + '/netmon';


export async function getDevices() {
    try {
        console.log('getDevices');
        const response = await axios.post(baseUrl + '/getDevices');
        netmonState_devices.value = response.data;
        return response.data;
        
    } catch (error) {
        console.error(error);
        return [];
    }

}
export async function getPowerMonDevices() {
    try {
        console.log('getPowerMonDevices');
        const response = await axios.post(baseUrl + '/getPowerMonDevices');
        netmonState_powerMonDevices.value = response.data;
        
    } catch (error) {
        console.error(error);
        
    }
}
export async function getPowerMons(device,duration) {
    try {
        console.log('getPowerMons device',device,'duration',duration,'baseUrl',baseUrl + '/getPowerMons');
        let data = { deviceName:device, duration:duration}
        const response = await axios.post(baseUrl + '/getPowerMons',data);
        netmonState_powerMonitors.value = response.data;
        //console.log('getPowerMons', response.data);
        return response.data;
        
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function getP2PConfigs(deviceName) {
    try {
        console.log('getP2PConfigs');
        const response = await axios.post(baseUrl + '/getP2pSpeedConfig', { deviceName });
        netmonState_p2pConfigs.value = response.data;
        
    } catch (error) {
        console.error(error);
        
    }
}
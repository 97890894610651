import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, } from 'react-bootstrap';
import NetmonDevices from '../netmon/netmonDevices.jsx';
import Dashboard from '../dashboard/Dashboard.jsx';

import Header from "../../Header";
import Footer from "../../footer";


import { useMediaQuery } from '@material-ui/core';
import '../../App.css';

//Components
const Home = () => {
  const dispatch = useDispatch();
 const { currentUser } = useSelector(store => store.user);


  const isMobile = useMediaQuery('(max-width: 599px)');
  const isDesktop = useMediaQuery('(min-width: 600px)');

  return (
   <>
   <Header/>
    <section class='main-layout'>
      <>
    {currentUser.isLoggedIn ? <Dashboard /> : ""}
      
       </> 
      
      
    </section>
    <Footer/>
    </>
   
  ); 
};

export default Home;
